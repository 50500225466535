import React from 'react';
import Layout from '../components/layout/Layout';
import GenericPageTopHeader from '../components/GenericPageTopHeader';

const NotFoundPage = () => {
return (
    <Layout>
        <GenericPageTopHeader pageTitle='404' className='container mx-auto my-4 text-4xl' />
        <section className="container mx-auto">
            <div>
                The resource you're attempting to access is not available.
            </div>
        </section>
    </Layout>
);
}

export default NotFoundPage
